.button_disabled {
  color: black;
  border: 1px solid black;
  background-color: #e5e5e5;
  opacity: 20%;
  cursor: default;
}

.button_disabled:hover {
  opacity: 20%;
}
