.profile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 920px) {
  .profile {
    margin-left: 19px;
    margin-right: 19px;
  }
}

@media screen and (max-width: 650px) {
  .profile {
    flex-direction: column;
    margin: 0;
  }
}
