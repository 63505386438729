.button_type_edit {
  width: 24px;
  height: 24px;
  display: flex;
  transition: opacity 0.4s;
  cursor: pointer;
}

.button_type_edit:hover {
  opacity: 60%;
}

@media screen and (max-width: 650px) {
  .button_type_edit {
    width: 18px;
    height: 18px;
  }
}
