.popup__title {
  margin-top: 34px;
  margin-left: 36px;
  margin: 34px 0 0 36px;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.21;
}

.popup__window_type_info .popup__title {
  margin: 0;
  font-size: 20px;
}



@media screen and (max-width: 425px) {
  .popup__title {
    margin-top: 25px;
    margin-left: 22px;
    font-size: 18px;
  }
}
