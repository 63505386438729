.page {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  min-height: 100vh;
  width: 100%;
  height: 100%;

  background-color: #000;
  color: #ffffff;
  box-sizing: border-box;
}
