.profile__name {
  margin: 0 18px 0 0;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.14;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 650px) {
  .profile__name {
    font-size: 22px;
    line-height: 1.21;
    margin: 0 10px 0 0;
  }
}
