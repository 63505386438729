.footer {
  max-width: 880px;
  margin: 68px auto 0;
  padding-bottom: 60px;
}

@media screen and (max-width: 920px) {
  .footer {
    /* margin-left: 19px; */
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .footer {
    margin: 50px 19px 0;
    padding-bottom: 30px;
    
  }
}
