.profile__description {
  margin: 16px 0 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 650px) {
  .profile__description {
    font-size: 14px;
    line-height: 1.21;
    margin-top: 14px;
  }
}
