.popup__window {
  background-color: #fff;
  width: 430px;
  border-radius: 10px;
  color: #000;
  position: relative;
}

@media screen and (max-width: 470px) {
  .popup__window {
    width: 282px;
    margin: auto;
  }
}
