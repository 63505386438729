.cards-list {
  margin: 0;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 282px);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  gap: 20px 17px;
}

@media screen and (max-width: 425px) {
  .cards-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
