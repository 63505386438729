.form__input {
  all: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 20%);
  min-height: 27px;
  line-height: 1.21;
  font-size: 14px;
  /* margin-bottom: 30px; */
}

@media screen and (max-width: 425px) {
  .form__input {
    min-height: 35px;
    margin-bottom: 21px;
  }
}
