.form-page__form {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  
}

@media screen and (max-width: 650px) {
  .form-page__form {
    padding: 0 30px 0;
  }
  
}