.profile__image-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear;
}

.profile__image:hover .profile__image-overlay {
  visibility: visible;
  opacity: 1;
}
