.button_type_add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 2px;
  margin-left: auto;
  box-sizing: border-box;
  cursor: pointer;
  transition: opacity 0.4s;
}

.button_type_add:hover {
  opacity: 60%;
}

@media screen and (max-width: 650px) {
  .button_type_add {
    margin-left: 0;
    width: 282px;
  }
}
