.cards-list__image-title {
  margin: 0;
  color: #000;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.21;
  max-width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
