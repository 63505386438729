.header__nav-link {
  transition: opacity ease 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  /* color: #A9A9A9; */
}

.header__nav-link:hover {
  opacity: 60%;
}

.user-details .header__nav-link {
  font-size: 18px;
  line-height: 22px;
}

@media screen and (max-width: 650px) {
  .header__nav-link {
    font-size: 14px;
    line-height: 17px;
  }
}