.popup {
  display: flex;
  position: fixed;
  background-color: rgb(0 0 0 / 50%);
  z-index: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.7s, opacity 0.7s linear;
}
